<template>
  <div class="smart-city">
    <div class="city-text w">
      <div class="manage-btn">
        <span>智慧城市</span>
      </div>
      <div class="instruction">
        <!-- <div class="title">智慧城市</div> -->
        <p>
          智慧城市是指利用各种信息技术，将城市的系统和服务打通、集成，以提升资源运用的效率，优化城市管理和服务，以及改善市民生活质量的一种创新概念。
        </p>
        <p>
          智慧城市是要把新一代信息技术，充分运用在城市中各行各业去，是下一代城市创新信息化的高级形态。将城市实现信息化与工业化深度融合，提高城镇化质量，实现城市的精细化和动态管理，并提升城市管理成效和改善市民生活质量。
        </p>
      </div>
      <div class="instruction">
        <div class="title">城市大脑</div>
        <p class="bott">
          基于人工智能和大数据技术，通过整合城市的数据资源，实现城市的数据化管理。将城市中的交通、建筑、环境等环节的大数据，都进行智能处理，以实现高效便捷的管理功能，让整个城市更智能、群众办事更便利。
        </p>
        <div class="title">智能档案</div>
        <p>
          利用智能管理系统，实现档案业务的线上归档及处理功能。将城市的各种数据资料，整理成数字化档案，合理利用便捷化的系统和流程，解决原本通过人工归档效率低、错误率高等痛点，实现高效的智能档案管理流程。
        </p>
      </div>
      <div class="imgs-s">
        <img src="../assets/imgs/cityImg.png" alt="" />
      </div>
    </div>
    <div class="dinning-room">
      <div class="bg-img">
        <div class="text-title">
          <span>应用领域</span>
          <p>
            打造智慧城市建设，从基础设施到整个城市的时间、空间数据，建立城市治理新模式
          </p>
        </div>
      </div>
      <div class="dinning w">
        <span v-for="item of smartCityData" :key="item.id">
          <div class="imgs-box">
            <!-- <img :src="baseIP+imageShowAPI+item.imageUri" alt="" /> -->
            <!-- <img :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" /> -->
            <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
            <img v-else :src="defaultImage" alt="" />
          </div>
          <div class="title">{{item.title}}</div>
        </span>
      </div>
    </div>
    <div class="park w">
      <div class="text-title">
        <span>智慧园区展示</span>
        <p>园区的智能化管理，从各方面细节出发，实现全方位的数字化</p>
      </div>
      <div class="park-list w">
        <div class="park-left">
          <img src="../assets/imgs/Floorplan.jpg" alt="" />
        </div>
        <div class="park-right">
          <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>智慧门禁管理</span>
            </div>
            <p>运用自动化的门禁系统，提高园区安全管理的先进性和提升用户体验感，提高园区管理的安全性能。</p>
          </div>
          <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>访客综合管理</span>
            </div>
            <p>实现访客自动管理流程，使用线上预约、登记的流程，将不同种类的访客进行精准管理，并全程智能引导其按指定线路进出。</p>
          </div>
          <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>智能停车管理</span>
            </div>
            <p>实现人车分流管理，运用车牌自动识别管理系统，做到入场智能登记、离场精准计费收费的功能，减少现场值守人员。</p>
          </div>
          <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>信息发布系统（公共广播系统）</span>
            </div>
            <p>将园区的公共广播系统统一进行数字化管理，提升园区的宣传能力，实现广告服务收入，同时提高安全通知能力。</p>
          </div>
          <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>智慧路灯管理</span>
            </div>
            <p>将一般路灯升级成能承载多类应用的智能路灯系统，并实现到点自动开关功能，便于节能化以及降耗管理。</p>
          </div>
          <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>指挥中心系统</span>
            </div>
            <p>将全园区的数据和情况统一进行整合，实现突发事件的全方位可防、可控，做到日常全园区能统一指挥、统一管理。</p>
          </div>
          <!-- <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>智能安防管理</span>
            </div>
            <p>多系统联动、事前主动预防、提高园区安全性、减少安保人员数</p>
          </div> -->
          <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>公共增值服务</span>
            </div>
            <p>根据具体的需求，提供额外的数字化服务功能，丰富服务内容，增加园区创收途径</p>
          </div>
          <!-- <div class="text-list">
            <div class="title">
              <img src="../assets/imgs/zhihuimao.png" alt="" />
              <span>多媒体会议/路演系统</span>
            </div>
            <p>提高服务能力，租赁创收</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getColumnContent } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
        smartCityData: []
      }
    },
    created() {
      getColumnContent({ optionType: '3' }).then((res) => {
        if (res.code === 0) {
          this.smartCityData = res.data.content.slice(0, 3)
        }
      })
    }
  };
</script>

<style lang="scss" scoped>
  .smart-city {
    .city-text {
      margin-bottom: 35px;
      .manage-btn {
        width: 100%;
        height: 64px;
        line-height: 64px;
        margin-top: 70px;
        margin-bottom: 30px;
        span {
          display: block;
          width: 142px;
          height: 64px;
          margin: 0 auto;
          text-align: center;
          line-height: 64px;
          border-radius: 4px;
          color: #333333;
          font-size: 34px;
        }
      }
      .instruction {
        margin-bottom: 20px;
      }
      .title {
        height: 40px;
        line-height: 40px;
        font-size: 22px;
        color: #333333;
        text-align: left;
      }
      .bott {
        margin-bottom: 20px;
      }
      p {
        text-indent: 2em;
        line-height: 30px;
        font-size: 18px;
        color: #666666;
        text-align: left;
        letter-spacing: 0px;
      }
      .imgs-s {
        width: 1400px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .dinning-room {
      width: 100%;
      position: relative;
      .bg-img {
        width: 100%;
        // height: 900px;
        height: 418px;
        padding-top: 86px;
        background: url('../assets/imgs/cityBg.png') no-repeat;
        background-size: 100%;
        box-sizing: border-box;
      }
      .dinning {
        height: 475px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 33px;
        top: 160px;
        left: 50%;
        margin-left: -700px;
        span {
          display: block;
          width: 444px;
          height: 343px;
          box-shadow: 0px 5px 10px 0px rgba(56, 56, 56, 0.13);
          cursor: pointer;
          .imgs-box {
            width: 444px;
            height: 271px;
            overflow: hidden;
            img {
              display: block;
              width: 100%;
              height: 100%;
              transition: all 0.9s;
            }
          }
          img:hover {
            transform: scale(1.2);
          }
        }
        span:nth-child(2) {
          margin: 0 35px;
        }
        .title {
          height: 65px;
          line-height: 65px;
          text-align: center;
          font-size: 22px;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .text-title {
        width: 665px;
        // height: 120px;
        line-height: 40px;
        margin: 0 auto;
        margin-bottom: 70px;
        span {
          height: 40px;
          line-height: 40px;
          font-size: 34px;
          color: #ffffff;
        }
        p {
          line-height: 30px;
          font-size: 16px;
          color: #ffffff;
          margin: 10px 0;
        }
      }
    }
    .park {
      margin-top: 300px;
      margin-bottom: 114px;
      .text-title {
        width: 665px;
        // height: 120px;
        line-height: 40px;
        margin: 0 auto;
        margin-bottom: 70px;
        span {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          font-size: 34px;
          color: #000000;
        }
        p {
          line-height: 30px;
          font-size: 16px;
          color: #999999;
          margin: 10px 0;
        }
      }
      .park-list {
        height: 452px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 5px 10px 0px rgba(56, 56, 56, 0.13);
        .park-left {
          width: 802px;
          height: 452px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: all 0.9s;
          }
          :hover {
            transform: scale(1.2);
          }
        }
        .park-right {
          width: 635px;
          height: 410px;
          box-sizing: border-box;

          overflow: auto;
          padding: 10px;
          margin-top: -50px;
          box-sizing: border-box;
          background-color: #ffffff;
          .text-list {
            width: 100%;
            // height: 70px;
            line-height: 70px;
            margin-bottom: 10px;
            cursor: pointer;
            .title {
              height: 40px;
              line-height: 40px;
              text-align: left;
              font-size: 20px;
              color: #333333;
              img {
                display: inline-block;
                width: 22px;
                height: 22px;
                vertical-align: middle;
                margin-right: 12px;
              }
            }
            p {
              line-height: 30px;
              font-size: 16px;
              color: #666666;
              text-align: left;
              // text-indent: 2em;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
            }
          }
        }
        /*滚动条样式*/
        .park-right::-webkit-scrollbar {
          width: 4px;
          /*height: 4px;*/
        }
        .park-right::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(179, 174, 174, 0.2);
          background: rgba(0, 0, 0, 0.2);
        }
        .park-right::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 5px rgba(173, 170, 170, 0.2);
          border-radius: 0;
          background: rgba(158, 156, 156, 0.1);
        }
      }
    }
  }
</style>